import React from "react";
import './testimonials.css'
import AVTR1 from '../../assets/netmagmedia.png'
import AVTR2 from '../../assets/glitch.png'
import AVTR3 from '../../assets/microsoft.png'
import AVTR4 from '../../assets/codecademy.png'
import AVTR5 from '../../assets/chipng.png'

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";



// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar: AVTR1,
    name: 'netMAGmedia',
    review: 'Deputy Editor'
  },
  {
    avatar: AVTR2,
    name: 'Glitch IT',
    review: 'IT Help Desk Technician'
  },
  {
    avatar: AVTR3,
    name: 'Microsoft',
    review: 'Introduction to Programming'
  },
  {
    avatar: AVTR4,
    name: 'CodeCademy',
    review: 'Full Stack Engineer'
  },
  {
    avatar: AVTR5,
    name: 'University of Chichester',
    review: 'Creative Writing and English Language Degree'
  }
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Work and Education</h5>
      <h2>Experience</h2>

      <Swiper Swiper pagination={true} modules={[Pagination]} spaceBetween={40} className="mySwiper container testimonials_container ">
        {
          data.map(({avatar, name, review}, index) => {
            return (
              
              <SwiperSlide key={index} className="testimonial">
                <div className="client_avatar">
                  <img src={avatar} class="swiper-pic" alt="headshot" />
                </div>
                
                <h5 className='client_name'>{name}</h5>
                <small className='client_review'>{review}</small>
              </SwiperSlide>
            )
          })
        }

      </Swiper>
    </section>
  )
}

export default Testimonials