import React from 'react'
import './contact.css'
import {AiFillLinkedin} from 'react-icons/ai'
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_1zuizwh', 'template_mx3fcwd', form.current, 'lXw4z_3nhbWF0waT0')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Get in touch</h5>
      <h2>Contact me</h2>

      <div className="container contact_container">
        <div className="contact_options">
          
          <article className="contact_option">
            <AiFillLinkedin className='contact_option-icon' />
            <h4>LinkedIn</h4>
            <h5>Jack Wooler</h5>
            <a href="https://www.linkedin.com/in/jack-wooler-b11126b8/" target="_blank" rel="noreferrer">Connect with me</a>
          </article>
        
        </div>

        {/* END OF CONTACT OPTIONS */}
      
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your full name' required />
          <input type="email" name='email' placeholder='Your email address' required />
          <textarea name="message" rows="7" placeholder='Your message' required> </textarea>
          <button type='submit' className='btn btn-primary'>Send message</button>
        </form>
      
      </div>
    </section>
  )
}

export default Contact