import React from 'react'
import './footer.css'
import {BsLinkedin} from 'react-icons/bs'
import {AiFillGithub} from 'react-icons/ai'

const Footer = () => {
  return (
    <footer>
      <a href='#header' className='footer_logo'>Jack Wooler</a>

      <ul className='permalinks'>
        <li><a href='#header'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#services'>Services</a></li>
        <li><a href='#portfolio'>Portfolio</a></li>
        <li><a href='#testimonials'>Testimonials</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>

    <div className="footer_socials">
        <a href='https://www.linkedin.com/in/jack-wooler-b11126b8/' target="_blank" rel="noreferrer"><BsLinkedin /></a>
        <a href='https://github.com/Diagrid' target="_blank" rel="noreferrer"><AiFillGithub /></a>
    </div>

      <div className="footer_copyright">
        <small>&copy; Jack Wooler. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer