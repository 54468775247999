import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {AiFillGithub} from 'react-icons/ai'

const HeaderSocials = () => {
  return (
    <div className='header_socials'>
        <a href='https://www.linkedin.com/in/jack-wooler-b11126b8/' target="_blank" rel="noreferrer"><BsLinkedin /></a>
        <a href='https://github.com/Diagrid' target="_blank" rel="noreferrer"><AiFillGithub /></a>
    </div>
  )
}

export default HeaderSocials