import React from 'react'
import './about.css'
import ME from '../../assets/me-newfoundland.jpg'
import {BsFillAwardFill} from 'react-icons/bs'
import {FaUserFriends} from 'react-icons/fa'
import {MdLibraryBooks} from 'react-icons/md'

const About = () => {
  return (
    <section id='about'>
      
      <h5>Get to know</h5>
      <h2>About me</h2>

      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={ME} alt="About" />
          </div>
        </div>
        
        <div className="about_content">
          <div className="about_cards">

            <article className='about_card'>
              <BsFillAwardFill className='about_icon'/>
              <h5>Coding</h5>
              <small>2+ years</small>
            </article>

            <article className='about_card'>
              <FaUserFriends className='about_icon'/>
              <h5>Research</h5>
              <small>2+ years</small>
            </article>

            <article className='about_card'>
              <MdLibraryBooks className='about_icon'/>
              <h5>Journalism</h5>
              <small>7+ years</small>
            </article>
          </div>

            <p>
              Currently working as Deputy Editor for netMAGmedia, I have been involved in B2B publishing for over 7 years now, gaining experience in writing, editing, research and marketing. 
              In order to both widen my skillset and follow a lifelong passion, I have latterly trained to become a fullstack developer, with the intention of finding a career in this field. 
            </p>

            <a href="#contact" className='btn btn-primary'>Get in touch</a>

          </div>
        </div>

      

    </section>
  )
}

export default About