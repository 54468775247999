import React from 'react'
import './experience.css'
import bootstrap from '../../assets/icons/bootstrap.png'
import css from '../../assets/icons/css.png'
import html from '../../assets/icons/html.png'
import jquery from '../../assets/icons/jquery.png'
import js from '../../assets/icons/js.png'
import mysql from '../../assets/icons/mysql.png'
import nodejs from '../../assets/icons/nodejs.png'
import php from '../../assets/icons/php.png'
import python from '../../assets/icons/python.png'
import react from '../../assets/icons/react.png'
import wordpress from '../../assets/icons/wordpress.png'
import firebase from '../../assets/icons/firebase.png'
import c from '../../assets/icons/c++.png'
import sass from '../../assets/icons/sass.png'



const Experience = () => {
  return (
    <section id='experience'>
      <h5>My experience</h5>
      <h2>Languages</h2>
      
      <div className="container experience_container">
        <div className="experience_frontend">
          <h3>Front-end Development</h3>
          <div className="experience_content">
            
            <article className='experience_details'>
            <img src={html} className='logo' alt="html" />
            </article>

            <article className='experience_details'>
              <img src={css} className='logo' alt="css" />
            </article>

            <article className='experience_details'>
              <img src={js} className='logo' alt="js" />
            </article>

            <article className='experience_details'>
              <img src={bootstrap} className='logo' alt="bootstrap" />
            </article>

            <article className='experience_details'>
              <img src={react} className='logo' alt="react" />
            </article>

            <article className='experience_details'>
              <img src={jquery} className='logo' alt="jquery" />
            </article>

            <article className='experience_details'>
              <img src={wordpress} className='logo' alt="wordpress" />
            </article>

            <article className='experience_details'>
              <img src={sass} className='logo' alt="wordpress" />
            </article>

          </div>
        </div>


        {/* END OF FRONTEND */}


        <div className="experience_backend">
        <h3>Back-end Development</h3>
          <div className="experience_content">
            
            <article className='experience_details'>
              <img src={nodejs} className='logo' alt="node" />
            </article>

            <article className='experience_details'>
              <img src={mysql} className='logo'alt="mysql" />
            </article>

            <article className='experience_details'>
              <img src={php} className='logo' alt="php" />
            </article>

            <article className='experience_details'>
            <img src={python} className='logo' alt="python" />
            </article>

            <article className='experience_details'>
            <img src={firebase} className='logo' alt="firebase" />
            </article>

            <article className='experience_details'>
              <img src={c} className='logo' alt="wordpress" />
            </article>

          {/* END OF BACKEND */}

          </div>
        </div>
      
      </div>
      
    </section>
  )
}

export default Experience