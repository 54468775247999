import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import 'bootstrap/dist/css/bootstrap.min.css'

import './portfolio.css'
import IMG1 from '../../assets/portfolio1.png'
import IMG2 from '../../assets/portfolio2.png'
import IMG3 from '../../assets/portfolio3.png'
import IMG4 from '../../assets/portfolio4.png'
import IMG5 from '../../assets/portfolio5.png'

import PDF1 from '../../assets/pdf/1.png'
import PDF2 from '../../assets/pdf/2.png'
import PDF3 from '../../assets/pdf/3.png'
import PDF4 from '../../assets/pdf/4.png'
import PDF5 from '../../assets/pdf/5.png'
import PDF6 from '../../assets/pdf/6.png'



const popover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3"><span class="black">Building Insights</span></Popover.Header>
    <Popover.Body>
      A static site built to launch the Building Insights podcast, using HTML, JS and CSS with Boostrap.
    </Popover.Body>
  </Popover>
);

const popover2 = (
  <Popover id="popover-basic">
    <Popover.Header as="h3"><span class="black">netMAG Research</span></Popover.Header>
    <Popover.Body>
      Built to host netMAGmedia's various research endeavours, using HTML, JS and CSS with Boostrap.
    </Popover.Body>
  </Popover>
);

const popover3 = (
  <Popover id="popover-basic">
    <Popover.Header as="h3"><span class="black">Darlene</span></Popover.Header>
    <Popover.Body>
      A text-adventure game, built with a friend using HTML, JS, CSS and the OkayBenji text-engine.
    </Popover.Body>
  </Popover>
);

const popover4 = (
  <Popover id="popover-basic">
    <Popover.Header as="h3"><span class="black">Gazetteer</span></Popover.Header>
    <Popover.Body>
      Gazetteer is an interactive map, providing weather, news and information about any country the user desires. The project utilises HTML, CSS, JS, JQuery, PHP and Leaflet.JS
    </Popover.Body>
  </Popover>
);

const popover5 = (
  <Popover id="popover-basic">
    <Popover.Header as="h3"><span class="black">Company Directory</span></Popover.Header>
    <Popover.Body>
      This Company Directory was built as a one-page application using HTML, CSS, Boostrap, JS, JQuery, PHP and MySQL.
    </Popover.Body>
  </Popover>
);



const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Building Insights - the construction podcast',
    github: 'https://github.com/Diagrid',
    demo: 'https://architectsdatafile.co.uk/home/podcast/',
    modalTitle: 'Test',
    ModalText: 'tesing',
    popoverNumber: popover
  },
  {
    id: 2,
    image: IMG2,
    title: 'netMAG research - construction White Papers',
    github: 'https://github.com/Diagrid',
    demo: 'https://www.hbdonline.co.uk/home/research/test/',
    modalTitle: 'Test 2',
    ModalText: 'tesing',
    popoverNumber: popover2
  },
  {
    id: 3,
    image: IMG3,
    title: 'Darlene - a text adventure game [joint-author]',
    github: 'https://github.com/Diagrid/darlene',
    demo: 'https://jackwooler.co.uk/darlene/',
    modalTitle: 'Test 3',
    ModalText: 'tesing',
    popoverNumber: popover3
  },
  {
    id: 4,
    image: IMG4,
    title: 'Gazetteer - interactive map',
    github: 'https://github.com/Diagrid/atlas',
    demo: 'https://jackwooler.co.uk/gazetteer/',
    modalTitle: 'Test 4',
    ModalText: 'tesing',
    popoverNumber: popover4
  },
  {
    id: 5,
    image: IMG5,
    title: 'Company Directory - content management system',
    github: 'https://github.com/Diagrid/companyDirectory',
    demo: 'https://jackwooler.co.uk/companyDirectory/',
    modalTitle: 'Test 5',
    ModalText: 'tesing',
    popoverNumber: popover5
  }
]

const data2 = [
  {
    id: 1,
    image: PDF1,
    title: 'Building Safety Into Law - White Paper',
    pdf: 'https://jackwooler.co.uk/pdf/1.pdf'
  },
  {
    id: 2,
    image: PDF2,
    title: 'Meeting The Needs of An Ageing Population - White Paper',
    pdf: 'https://jackwooler.co.uk/pdf/2.pdf'
  },
  {
    id: 3,
    image: PDF3,
    title: 'Responding to Change - White Paper',
    pdf: 'https://jackwooler.co.uk/pdf/3.pdf'
  },
  {
    id: 4,
    image: PDF4,
    title: 'The Francis Crick Institute - Project Report',
    pdf: 'https://jackwooler.co.uk/pdf/4.pdf'
  },
  {
    id: 5,
    image: PDF5,
    title: 'Revisiting Fire Safety - White Paper',
    pdf: 'https://jackwooler.co.uk/pdf/5.pdf'
  },
  {
    id: 6,
    image: PDF6,
    title: 'One Park Drive - Case Study',
    pdf: 'https://jackwooler.co.uk/pdf/6.pdf'
  }
]


function Portfolio() {

  return (
    <>
    

    <section id='portfolio'>
      <h5>Portfolio</h5>
      <h2>Web Development</h2>

      <div className="container portfolio_container">
        {
          data.map(({id, image, title, github, demo, popoverNumber}) => {
            return (
              <article key={id} className='portfolio_item'>
                <div className="portfolio_item-image">
                  <img src={image} alt={title}></img>
                </div>
              <h3>{title}</h3>
                <div className="portfolio_item-cta">
                  <a href={github} className='btn' target='_blank' rel="noreferrer">Github</a>
                  <a href={demo} className='btn btn-primary' target='_blank' rel="noreferrer">Demo</a>
                  <OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverNumber}>
                    <Button variant="">About</Button>
                  </OverlayTrigger>
                </div>
              </article>
            )
          })
        }
        
        
      </div>
      

      <section>
      <h5>Portfolio</h5>
      <h2>Writing and Research</h2>
      
      <div className="container portfolio_container">
        {
          data2.map(({id, image, title, pdf}) => {
            return (
              <article key={id} className='portfolio_item'>
                <div className="portfolio_item-image">
                  <img src={image} alt={title}></img>
                </div>
              <h3>{title}</h3>
                <div className="portfolio_item-cta">
                  <a href={pdf} className='btn btn-primary' target='_blank' rel="noreferrer">PDF</a>
                </div>
              </article>
            )
          })
        }
        
      </div>
    </section>
    </section>

    </>
  )
}

export default Portfolio