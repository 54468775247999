import React from 'react'
import './services.css'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I offer</h5>
      <h2>Services</h2>

      <div className="container services_container">
        
        <article className="service">
            <div className="service_head">
              <h3>Web Development</h3>
            </div>

            <ul className='service_list'>
              <li>
                <p>-</p>
                <p>Single page applications using a range of popular languages.</p>
              </li>
              <li>
                <p>-</p>
                <p>Safe and effective back-end development.</p>
              </li>
              <li>
                <p>-</p>
                <p>User experience and interfaces, ensuring clean, effective, enjoyable use.</p>
              </li>
              <li>
                <p>-</p>
                <p>Quick to pick up new languages, with a desire to learn as much as possible, and the patience to do so.</p>
              </li>
              <li>
                <p>-</p>
                <p>A team player, working hard to ensure I'm contributing in the most effective way, while listening to and learning from those around me.</p>
              </li>
            </ul>
        </article>

        {/* END OF UI/UX */}
      
        <article className="service">
            <div className="service_head">
              <h3>Content Creation</h3>
            </div>

            <ul className='service_list'>
              <li>
                <p>-</p>
                <p>Writing news, features, case studies and research reports for varying construction audiences.</p>
              </li>
              <li>
                <p>-</p>
                <p>Proofing, subbing and editing copy to a high standard.</p>
              </li>
              <li>
                <p>-</p>
                <p>Sourcing and curating content from recognised names and brands.</p>
              </li>
              <li>
                <p>-</p>
                <p>Continually developing new content ideas and directions.</p>
              </li>
              <li>
                <p>-</p>
                <p>Podcast launching, recording, hosting and editing.</p>
              </li>
              <li>
                <p>-</p>
                <p>Always looking to improve, learning from peers and competitors alike.</p>
              </li>
            </ul>
        </article>

        {/* END OF WEB DEVELOPMENT */}

        <article className="service">
            <div className="service_head">
              <h3>Publishing</h3>
            </div>

            <ul className='service_list'>
              <li>
                <p>-</p>
                <p>Magazine layouts, using Quark and Indesign.</p>
              </li>
              <li>
                <p>-</p>
                <p>Photography and image editing.</p>
              </li>
              <li>
                <p>-</p>
                <p>Event hosting.</p>
              </li>
              <li>
                <p>-</p>
                <p>Marketing and audience control.</p>
              </li>
              <li>
                <p>-</p>
                <p>Building and maintaing industry relationships.</p>
              </li>
              <li>
                <p>-</p>
                <p>Industry and audience research, keeping up to date with the key topics and events of the moment.</p>
              </li>
            </ul>
        </article>

        <article className="service">
            <div className="service_head">
              <h3>Free time</h3>
            </div>

            <ul className='service_list'>
              <li>
                <p>-</p>
                <p>DIY electronics, building guitar pedals and a modular synthesizer from the circuit boards up.</p>
              </li>
              <li>
                <p>-</p>
                <p>Music recording and production using guitar, bass, piano and synthesizers.</p>
              </li>
              <li>
                <p>-</p>
                <p>Fiction writing and a keen reader.</p>
              </li>
              <li>
                <p>-</p>
                <p>Game development, primarily as web-apps.</p>
              </li>
              <li>
                <p>-</p>
                <p>Computing, including Raspberry Pi projects and basic analogue circuit design.</p>
              </li>
            </ul>
        </article>

        {/* END OF UI/UX */}
      
      {/* END OF CONTENT CREATION */}

      </div>


    </section>
  )
}

export default Services